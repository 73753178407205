const sync = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <title>Sync</title>
    <path
      d="M20.488 10.796c.37 2.554-.408 5.243-2.398 7.206-2.94 2.904-7.474 3.262-10.81 1.08l2.337-2.281-8.588-1.2L2.227 24l2.623-2.516c4.71 3.477 11.39 3.149 15.669-1.08C23 17.954 24.13 14.7 23.99 11.488l-3.503-.692zM5.944 6.002c2.939-2.904 7.473-3.264 10.808-1.08l-2.34 2.28L23 8.402 21.802 0l-2.62 2.522C14.473-.958 7.795-.627 3.515 3.602 1.033 6.052-.098 9.306.042 12.516l3.503.691c-.37-2.555.41-5.241 2.4-7.205z"
      fill="#000"
      fillRule="nonzero"
    />
  </svg>
)

export default sync
